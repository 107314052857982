import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, Cell } from '@trbl/react-css-grid';
import Image from 'gatsby-image';
import './Header.scss';

const Header = React.memo(({
  subheading,
  heading,
  background,
  color,
}) => {
  
  const { sideImage, sideImageMobile } = useStaticQuery(graphql`
    query headerImage {
      sideImage: file(relativePath: {eq: "psu-fans.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 720, maxWidth: 300 quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sideImageMobile: file(relativePath: {eq: "psu-fans-mobile.jpg"}) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sideImageSources = [
    sideImageMobile.childImageSharp.fluid,
    {
      ...sideImage.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ];
  return (
    <div className="wmfab__header">
      <Grid
        className="wmfab__header--grid"
      >
        <Cell
          className="wmfab__header--container"
          hStart={2}
          hSpan={15}
        >
          <Image
            className="wmfab__header--background"
            fluid={background.childImageSharp.fluid}
            style={{ height: "100%"}}
            fadeIn={false}
            loading="eager"
          />
          <div className="wmfab__header--background-overlay-gradient" />
          <div
            className={`
              wmfab__header--background-overlay
              wmfab__overlay--${color ? color : ''}
            `}
          />
          <header className="wmfab__header--content">
            <h1>
              <span className="wmfab__type--subheader">
                {subheading}—
              </span>
              {heading}
            </h1>
          </header>
        </Cell>
      </Grid>
      <Grid
        className="wmfab__header--side"
      >
        <Cell
          className="wmfab__header--side-container"
          hStart={0}
          hSpan={3}
        >
          <Image
            fluid={sideImageSources}
            style={{ height: '100%' }}
            loading="eager"
            fadeIn={false}
          />
          <div className="wmfab__header--side-overlay" />
        </Cell>
      </Grid>
    </div>
  );
});

export default Header;
